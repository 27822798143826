import { INavLink, INavLinkGroup, INavStyles, Nav } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getItem, setItem } from '../common/local-storage';
import { featureToggles } from '../config';
import { AccountPermissionSet, useAccount } from '../hooks';

const navStyles: Partial<INavStyles> = {
    root: {
        boxSizing: 'border-box',
        overflowY: 'auto',
    },
    // these link styles override the default truncation behavior
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
    groupContent: {
        marginBottom: 0,
    },
};

type ILeftMenuLink = INavLink & {
    allowed?: (permissions: AccountPermissionSet) => boolean;
    links?: ILeftMenuLink[];
};

type ILeftMenuLinkGroup = INavLinkGroup & {
    links: ILeftMenuLink[];
};

function findSelectedChild(location: string, links: INavLink[]): string | undefined {
    for (const link of links) {
        if (link.links) {
            const selected = findSelectedChild(location, link.links);

            if (selected) {
                toggleItemExpansionAndStoreGroupExpansion(link);
                return selected;
            }
        }

        if (location.startsWith(link.url)) {
            return link.key;
        }
    }
}

function findSelectedLink(location: string, nav: INavLinkGroup[]) {
    for (const group of nav) {
        const selected = findSelectedChild(location, group.links);

        if (selected) {
            return selected;
        }
    }
}

function remapLink(link: ILeftMenuLink, permissions: AccountPermissionSet): INavLink | undefined {
    const allowed = getVisibilityForLinksAndGroupedLinks(link, permissions);
    link.url = getUrlForGroupLinksOrDefault(link, permissions);

    if (allowed) {
        const { allowed, ...props } = link;

        const links = link.links && remapLinkList(link.links, permissions);

        if (links?.length === 1) {
            return links[0];
        }

        return {
            ...props,
            key: getKeyFromUrl(link.url),
            links: links,
            isExpanded: getExpansionFromLocalStorage(link),
        };
    }
}

function remapLinkList(links: ILeftMenuLink[], permissions: AccountPermissionSet): INavLink[] {
    return links.reduce<INavLink[]>((children, link) => {
        const remapped = remapLink(link, permissions);

        if (remapped) {
            children.push(remapped);
        }

        return children;
    }, []);
}

function remapLinkGroup(group: ILeftMenuLinkGroup, permissions: AccountPermissionSet): INavLinkGroup {
    return {
        ...group,
        links: group.links && remapLinkList(group.links, permissions),
    };
}

const ExpandedMenuGroupStorageKey = 'EXPANDED_MENU_GROUPS';

function toggleItemExpansionAndStoreGroupExpansion(navLinkItem: INavLink | undefined): void {
    if (navLinkItem) {
        storeOrCreateGroupExpansionStateInLocalStorage(navLinkItem, true);
        navLinkItem.isExpanded = true;
    }
}

function getUrlForGroupLinksOrDefault(link: ILeftMenuLink, permissions: AccountPermissionSet): string {
    if (link.links?.length) {
        const firstChild = link.links.find((l) => l.allowed?.(permissions));
        if (firstChild) {
            return firstChild.url;
        }
    }
    return link.url;
}
function getVisibilityForLinksAndGroupedLinks(link: ILeftMenuLink, permissions: AccountPermissionSet): boolean {
    const allowed = link.allowed?.(permissions) ?? true;

    if (link.links?.length) {
        return link.links.find((l) => (l.allowed?.(permissions) ?? false) === true) !== undefined;
    }

    return allowed;
}

function getExpansionFromLocalStorage(link: ILeftMenuLink): boolean | undefined {
    const expandedGroups = getItem<string[]>(ExpandedMenuGroupStorageKey);
    return expandedGroups?.find((group) => group === link.url) !== undefined;
}

function storeOrCreateGroupExpansionStateInLocalStorage(navLinkItem: INavLink, isExpanded: boolean) {
    if (navLinkItem.links?.length) {
        const expandedGroups = getItem<string[]>(ExpandedMenuGroupStorageKey) ?? [];
        const filteredExpandedGroups = expandedGroups?.filter((group) => group !== navLinkItem.url);

        if (isExpanded) {
            filteredExpandedGroups.push(navLinkItem.url ?? '');
        }

        setItem(ExpandedMenuGroupStorageKey, filteredExpandedGroups);
    }
}

function scrollToSelectedLinkItem(
    item: INavLink | undefined,
    props: ((props?: INavLink | undefined) => JSX.Element | null) | undefined,
    selected: string | undefined,
): JSX.Element | null {
    if (item && props) {
        item.id = item.key;
        const element = props(item);

        if (item.key && item.key === selected) {
            const element = document.getElementById(item.key);
            if (element && !isInViewport(element)) {
                element.scrollIntoView();
            }
        }

        return element;
    }

    return null;
}

function getScrollParent(element: HTMLElement | null): HTMLElement | undefined {
    if (element) {
        if (element.scrollHeight > element.clientHeight) {
            return element;
        } else {
            return getScrollParent(element.parentElement);
        }
    }
}

function isInViewport(element: HTMLElement): boolean {
    const { bottom, height, top } = element.getBoundingClientRect();
    const containerRect = getScrollParent(element)?.getBoundingClientRect();

    if (containerRect) {
        return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }
    return true;
}

function getKeyFromUrl(url: string | undefined): string | undefined {
    return url?.replaceAll('/', '-');
}

export type LeftMenuProps = {
    onLinkClick?: (ev?: React.MouseEvent<HTMLElement>) => void;
};

export const LeftMenu: React.FC<LeftMenuProps> = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();
    const navigate = useNavigate();

    const location = useLocation();

    // adding an empty title string to each link removes the tooltip;
    // it's unnecessary now that the text wraps, and will not truncate
    const linkGroups: ILeftMenuLinkGroup[] = useMemo(
        () => [
            {
                links: [
                    {
                        name: t('menu.dashboard'),
                        url: '/dashboard/realtime',
                        links: [
                            {
                                iconProps: { iconName: 'SpeedHigh' },
                                name: t('menu.realtimeDashboard'),
                                url: '/dashboard/realtime',
                                allowed: (permissions) => permissions.dashboard.realtime,
                            },
                            {
                                iconProps: { iconName: 'CRMReport' },
                                name: t('menu.reportingDashboard'),
                                url: '/dashboard/reporting',
                                allowed: (permissions) => permissions.dashboard.reporting,
                            },
                        ],
                    },
                    {
                        name: t('menu.customers'),
                        url: '/customers',
                        links: [
                            {
                                iconProps: { iconName: 'AccountManagement' },
                                name: t('menu.customers'),
                                url: '/customers',
                                allowed: (permissions) => permissions.customers.view,
                            },
                            {
                                iconProps: { iconName: 'ReceiptCheck' },
                                name: t('menu.customerVerifications'),
                                url: '/customer-verifications',
                                allowed: (permissions) => permissions.customerVerifications.view,
                            },
                            {
                                iconProps: { iconName: 'ReceiptCheck' },
                                name: t('menu.customerDataModifications'),
                                url: '/customer-data-modifications',
                                allowed: (permissions) => permissions.customerDataModifications.view,
                            },
                            {
                                iconProps: { iconName: 'ReceiptCheck' },
                                name: t('menu.customerKycRequests'),
                                url: '/customer-kyc-requests',
                                allowed: (permissions) => permissions.customerKycRequests.view,
                            },
                            {
                                iconProps: { iconName: 'ReceiptCheck' },
                                name: t('menu.customerReKycVerifications'),
                                url: '/customer-re-kyc-verifications',
                                allowed: (permissions) => permissions.customerReKycVerifications.view,
                            },
                            {
                                iconProps: { iconName: 'ReceiptCheck' },
                                name: t('menu.customerPeriodicReviews'),
                                url: '/customer-periodic-reviews',
                                allowed: (permissions) =>
                                    featureToggles.customerPeriodicReviews && permissions.customerPeriodicReviews.view,
                            },
                            {
                                iconProps: { iconName: 'Timer' },
                                name: t('menu.otpAuthenticationFactors'),
                                url: '/otp-auth-factors',
                                allowed: (permissions) => permissions.otpAuthFactors.view,
                            },
                            {
                                iconProps: { iconName: 'PasswordField' },
                                name: t('menu.pins'),
                                url: '/pins',
                                allowed: (permissions) => permissions.pins.view,
                            },
                            {
                                iconProps: { iconName: 'Sort' },
                                name: t('menu.authenticationFlows'),
                                url: '/auth-flows',
                                allowed: (permissions) => featureToggles.authenticationFlows && permissions.authenticationFlows.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.transactions'),
                        url: '/transactions',
                        links: [
                            {
                                iconProps: { iconName: 'Switch' },
                                name: t('menu.transactions'),
                                url: '/transactions',
                                allowed: (permissions) => permissions.transactions.view,
                            },
                            {
                                iconProps: { iconName: 'QRCode' },
                                name: t('menu.transactionIntents'),
                                url: '/transaction-intents',
                                allowed: (permissions) => permissions.transactionIntents.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.identityVerifications'),
                        url: '/identity-verifications',
                        links: [
                            {
                                iconProps: { iconName: 'ReminderPerson' },
                                name: t('menu.identityVerifications'),
                                url: '/identity-verifications',
                                allowed: (permissions) => permissions.identityVerifications.view,
                            },
                            {
                                iconProps: { iconName: 'FabricUserFolder' },
                                name: t('menu.identityVerificationFiles'),
                                url: '/identity-verification-files',
                                allowed: (permissions) => permissions.identityVerificationFiles.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.customerRisk'),
                        url: '/customer-risk/rule-sets',
                        links: [
                            {
                                iconProps: { iconName: 'AllApps' },
                                name: t('menu.customerRiskRuleSets'),
                                url: '/customer-risk/rule-sets',
                                allowed: (permissions) => permissions.riskRuleSets.view,
                            },
                            {
                                iconProps: { iconName: 'ContactCard' },
                                name: t('menu.customerRiskProfiles'),
                                url: '/customer-risk/profiles',
                                allowed: (permissions) => permissions.riskProfiles.view,
                            },
                            {
                                iconProps: { iconName: 'ReportLibrary' },
                                name: t('menu.riskProfileRecalculations'),
                                url: '/customer-risk/recalculations',
                                allowed: (permissions) => permissions.riskRecalculations.view,
                            },
                            {
                                iconProps: { iconName: 'ReportLibrary' },
                                name: t('menu.customerTransactionAggregations'),
                                url: '/customer-risk/transaction-aggregations',
                                allowed: (permissions) =>
                                    featureToggles.transactionAggregations && permissions.transactionAggregations.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.customerQnA'),
                        url: '/customer-qna/questionnaires',
                        links: [
                            {
                                iconProps: { iconName: 'SurveyQuestions' },
                                name: t('menu.questionnaires'),
                                url: '/customer-qna/questionnaires',
                                allowed: (permissions) => permissions.customerQnAQuestionnaires.view,
                            },
                            {
                                iconProps: { iconName: 'FeedbackRequestSolid' },
                                name: t('menu.assignments'),
                                url: '/customer-qna/assignments',
                                allowed: (permissions) => permissions.customerQnAAssignments.view,
                            },
                        ],
                    },
                    {
                        iconProps: { iconName: 'PageArrowRight' },
                        name: t('menu.redirectSessions'),
                        url: '/redirect-sessions',
                        allowed: (permissions) => permissions.redirectSessions.view,
                    },
                    {
                        iconProps: { iconName: 'DocumentManagement' },
                        name: t('menu.documentRecognitions'),
                        url: '/document-recognitions',
                        allowed: (permissions) => permissions.documentRecognitions.view && featureToggles.documentRecognitions,
                    },
                    {
                        name: t('menu.distributors'),
                        url: '/distributors',
                        links: [
                            {
                                iconProps: { iconName: 'Home' },
                                name: t('menu.distributors'),
                                url: '/distributors',
                                allowed: (permissions) => permissions.distributors.view,
                            },
                            {
                                iconProps: { iconName: 'Shop' },
                                name: t('menu.shops'),
                                url: '/shops',
                                allowed: (permissions) => permissions.shops.view,
                            },
                            {
                                iconProps: { iconName: 'CellPhone' },
                                name: t('menu.devices'),
                                url: '/devices',
                                allowed: (permissions) => permissions.devices.view,
                            },
                            {
                                iconProps: { iconName: 'UserWarning' },
                                name: t('menu.userDeviceAuthorizations'),
                                url: '/user-device-authorizations',
                                allowed: (permissions) => permissions.devices.viewUserDeviceAuthorizations,
                            },
                        ],
                    },

                    {
                        iconProps: { iconName: 'OfficeStoreLogo' },
                        name: t('menu.merchants'),
                        url: '/merchants',
                        allowed: (permissions) => permissions.merchants.view,
                    },
                    {
                        name: t('menu.limits'),
                        url: '/limits',
                        links: [
                            {
                                iconProps: { iconName: 'NotExecuted' },
                                name: t('menu.limits'),
                                url: '/limits',
                                allowed: (permissions) => permissions.limits.view,
                            },
                            {
                                iconProps: { iconName: 'NotExecuted' },
                                name: t('menu.limitAccountSets'),
                                url: '/limit-account-sets',
                                allowed: (permissions) => permissions.limitAccountSets.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.settlement'),
                        url: '/settlement-export-files',
                        links: [
                            {
                                iconProps: { iconName: 'OpenFile' },
                                name: t('menu.settlementExportFiles'),
                                url: '/settlement-export-files',
                                allowed: (permissions) => permissions.settlementExportFiles.view,
                            },
                            {
                                iconProps: { iconName: 'DownloadDocument' },
                                name: t('menu.settlementImportFiles'),
                                url: '/settlement-import-files',
                                allowed: (permissions) => permissions.settlementImportFiles.view,
                            },
                        ],
                    },

                    {
                        iconProps: { iconName: 'Ringer' },
                        name: t('menu.notifications'),
                        url: '/notifications',
                        allowed: (permissions) => permissions.notifications.view,
                    },
                    {
                        iconProps: { iconName: 'Document' },
                        name: t('menu.documents'),
                        url: '/documents',
                        allowed: (permissions) => permissions.documents.view,
                    },
                    {
                        iconProps: { iconName: 'AlarmClock' },
                        name: t('menu.timeExpirations'),
                        url: '/time-expirations',
                        allowed: (permissions) => permissions.timeExpirations.view,
                    },

                    {
                        iconProps: { iconName: 'DataflowsLink' },
                        name: t('menu.affiliations'),
                        url: '/affiliations',
                        allowed: (permissions) => permissions.affiliations.view,
                    },

                    {
                        iconProps: { iconName: 'DownloadDocument' },
                        name: t('menu.billingFiles'),
                        url: '/billing-files',
                        allowed: (permissions) => permissions.billingFiles.view,
                    },
                    {
                        iconProps: { iconName: 'DownloadDocument' },
                        name: t('menu.transactionFiles'),
                        url: '/transaction-files',
                        allowed: (permissions) => permissions.transactionFiles.view,
                    },
                    {
                        iconProps: { iconName: 'People' },
                        name: t('menu.users'),
                        url: '/users',
                        allowed: (permissions) => permissions.users.view,
                    },
                    {
                        iconProps: { iconName: 'WaitlistConfirm' },
                        name: t('menu.changeRequests'),
                        url: '/change-requests',
                        allowed: (permissions) => permissions.changeRequests.view,
                    },
                    {
                        iconProps: { iconName: 'Education' },
                        name: t('menu.trainingAssignments'),
                        url: '/trainings',
                        allowed: (permissions) => permissions.trainingAssignments.view,
                    },
                    {
                        iconProps: { iconName: 'PaymentCard' },
                        name: t('menu.accounts'),
                        url: '/accounts',
                        allowed: (permissions) => permissions.accounts.view,
                    },
                    {
                        iconProps: { iconName: 'Fingerprint' },
                        name: t('menu.credentials'),
                        url: '/credentials',
                        allowed: (permissions) => permissions.credentials.view,
                    },
                    {
                        name: t('menu.reports'),
                        url: '/export-files/cbm-export-files',
                        links: [
                            {
                                iconProps: { iconName: 'OpenFile' },
                                name: t('menu.cbmExportFiles'),
                                url: '/export-files/cbm-export-files',
                                allowed: (permissions) => permissions.cbmExportFiles.view,
                            },
                            {
                                iconProps: { iconName: 'OpenFile' },
                                name: t('menu.cesopExportFiles'),
                                url: '/export-files/cesop-export-files',
                                allowed: (permissions) => permissions.cbmExportFiles.view,
                            },
                        ],
                    },
                    {
                        name: t('menu.system'),
                        url: '/system/status',
                        links: [
                            {
                                iconProps: { iconName: 'Health' },
                                name: t('menu.systemStatus'),
                                url: '/system/status',
                                allowed: (permissions) => permissions.systemStatus.view,
                            },
                            {
                                iconProps: { iconName: 'Embed' },
                                name: t('menu.apiIntegrations'),
                                url: '/system/apis',
                                allowed: (permissions) => permissions.apiIntegration.view,
                            },
                            {
                                iconProps: { iconName: 'Health' },
                                name: t('menu.monitoringRules'),
                                url: '/monitoring/rules',
                                allowed: (permissions) => permissions.monitoringRules.view,
                            },
                            {
                                iconProps: { iconName: 'Health' },
                                name: t('menu.monitoringAlerts'),
                                url: '/monitoring/alerts',
                                allowed: (permissions) => permissions.monitoringAlerts.view,
                            },
                        ],
                    },
                ],
            },
        ],
        [t],
    );

    const { permissions } = useAccount();
    const nav = useMemo(() => linkGroups.map((group) => remapLinkGroup(group, permissions)), [permissions, linkGroups]);
    const selected = useMemo(() => findSelectedLink(location.pathname, nav), [location.pathname, nav]);

    return (
        <Nav
            styles={navStyles}
            groups={nav}
            selectedKey={selected}
            focusZoneProps={{ className: classes.zone }}
            onRenderLink={(item, props) => scrollToSelectedLinkItem(item, props, selected)}
            onLinkExpandClick={(_, item) => {
                if (item != null) storeOrCreateGroupExpansionStateInLocalStorage(item, !item.isExpanded);
            }}
            onLinkClick={(event, item) => {
                event?.preventDefault();
                if (item?.url) {
                    navigate(item.url);
                }

                toggleItemExpansionAndStoreGroupExpansion(item);
                props.onLinkClick?.(event);
            }}
        />
    );
};

const useStyles = makeStyles({
    zone: {
        overflowY: 'auto',
    },
});
