import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

export type StackProps = {
    className?: string;
    horizontal?: boolean;
    responsive?: boolean;
    justify?: 'start' | 'end' | 'center' | 'stretch';
    align?: 'start' | 'end' | 'center' | 'stretch';
    wrap?: boolean;
    spacing?: 'small' | 'medium' | 'large' | 'none';
};

export const Stack: React.FC<PropsWithChildren<StackProps>> = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <div
            className={mergeClasses(
                classes.root,
                props.horizontal && classes.horizontal,
                props.responsive && classes.responsive,
                props.wrap && classes.wrap,

                props.justify === 'center' && classes.justifyCenter,
                props.justify === 'end' && classes.justifyEnd,
                props.justify === 'start' && classes.justifyStart,
                props.justify === 'stretch' && classes.justifyStretch,

                props.align === 'center' && classes.alignCenter,
                props.align === 'end' && classes.alignEnd,
                props.align === 'start' && classes.alignStart,
                props.align === 'stretch' && classes.alignStretch,

                props.spacing === 'none' && classes.spacingNone,
                props.spacing === 'small' && classes.spacingSmall,
                props.spacing === 'medium' && classes.spacingMedium,
                props.spacing === 'large' && classes.spacingLarge,

                props.className,
            )}
        >
            {children}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: tokens.spacingVerticalL,
        '> *': {
            textOverflow: 'ellipsis',
        },
    },
    responsive: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr)',
        alignItems: 'start',
        '@media only screen and (min-width: 1024px)': {
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
        },
    },
    horizontal: {
        flexDirection: 'row',
    },
    wrap: {
        flexWrap: 'wrap',
    },

    spacingNone: {
        gap: '0',
    },
    spacingSmall: {
        gap: tokens.spacingVerticalS,
    },
    spacingMedium: {
        gap: tokens.spacingVerticalL,
    },
    spacingLarge: {
        gap: tokens.spacingVerticalXXL,
    },

    justifyEnd: {
        justifyContent: 'end',
    },
    justifyStart: {
        justifyContent: 'start',
    },
    justifyCenter: {
        justifyContent: 'Center',
    },
    justifyStretch: {
        justifyContent: 'stretch',
    },

    alignEnd: {
        alignItems: 'end',
    },
    alignStart: {
        alignItems: 'start',
    },
    alignCenter: {
        alignItems: 'Center',
    },
    alignStretch: {
        alignItems: 'stretch',
    },
});
