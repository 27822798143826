import { makeStyles, Spinner } from '@fluentui/react-components';
import { useSetTimeout } from '@fluentui/react-hooks';
import { useEffect, useState } from 'react';

export const LoadingPage: React.FC = () => {
    const classes = useStyles();

    const [visible, setVisible] = useState(false);
    const { clearTimeout, setTimeout } = useSetTimeout();

    useEffect(() => {
        const timeout = setTimeout(() => setVisible(true), 500);

        return () => clearTimeout(timeout);
    }, [setTimeout, clearTimeout]);

    return <div className={classes.root}>{visible && <Spinner size="huge" />}</div>;
};

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
