import {
    Button,
    ButtonProps,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
    makeStyles,
    mergeClasses,
    tokens,
} from '@fluentui/react-components';
import { forwardRef, PropsWithChildren, useEffect, useState } from 'react';

export type DialogComponentProps<T = any> = {
    onDismissed?: (data?: T) => void;
};

export type DialogOpenReturn = void | boolean;

export type DialogButtonProps = Pick<ButtonProps, 'icon' | 'disabled' | 'appearance'> & {
    className?: string;
    dialog: React.ReactElement<DialogComponentProps>;
    size?: 'default' | 'medium' | 'large';
    inline?: boolean;
    onOpen?: () => DialogOpenReturn | Promise<DialogOpenReturn>;
};

export const DialogButton = forwardRef<HTMLButtonElement, PropsWithChildren<DialogButtonProps>>((props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [shown, setShown] = useState(false);

    useEffect(() => {
        if (open) {
            setShown(true);
        } else {
            setTimeout(() => {
                setShown(false);
            }, 500);
        }
    }, [open]);

    const content = {
        ...props.dialog,
        props: {
            ...props.dialog.props,
            onDismissed: (data: any) => {
                setOpen(false);

                props.dialog.props.onDismissed?.(data);
            },
        },
    };

    const buttonClicked = async () => {
        const proceed = await props.onOpen?.();

        if (proceed !== false) {
            setOpen(true);
        } else if (proceed === false) {
            setOpen(false);
        }
    };

    const appearance = props.inline ? 'transparent' : props.children ? (props.appearance ?? 'primary') : 'subtle';

    return (
        <Dialog modalType="alert" open={open} onOpenChange={(_, data) => setOpen(data.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    appearance={appearance}
                    icon={props.icon}
                    iconPosition={props.inline ? 'after' : undefined}
                    disabled={props.disabled}
                    onClick={buttonClicked}
                    className={mergeClasses(props.inline && classes.inline, props.className)}
                >
                    {props.children}
                </Button>
            </DialogTrigger>
            <DialogSurface
                backdrop={{ className: classes.surfaceBackdrop }}
                className={mergeClasses(
                    classes.surface,
                    props.size === 'medium' && classes.surfaceMedium,
                    props.size === 'large' && classes.surfaceLarge,
                )}
            >
                <DialogBody className={classes.body}>
                    <DialogContent>{shown && content}</DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

const useStyles = makeStyles({
    surface: {
        width: 'calc(100vw - 24px)',
        maxWidth: '560px',
        overflow: 'hidden',
    },
    surfaceMedium: {
        maxWidth: '960px',
    },
    surfaceLarge: {
        maxWidth: '1440px',
    },
    surfaceBackdrop: {
        backgroundColor: tokens.colorBackgroundOverlay,
    },
    body: {
        gap: '0',
    },
    inline: {
        fontWeight: tokens.fontWeightRegular,
        minWidth: 'unset',
        padding: '0',
    },
});
