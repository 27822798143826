import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

export type CardProps = {
    className?: string;
    content?: { className?: string };
    title?: string | React.ReactElement;
    icon?: React.ReactElement;
};

export const Card: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.root, props.className)}>
            {props.title && (
                <div className={classes.title}>
                    {props.icon}
                    {props.title}
                </div>
            )}
            <div className={props.content?.className}>{props.children}</div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        boxShadow: tokens.shadow4,
        borderRadius: tokens.borderRadiusMedium,
        padding: tokens.spacingHorizontalL,
        overflow: 'hidden',
        backgroundColor: tokens.colorNeutralBackground1,
        '@media print': {
            boxShadow: 'unset',
            border: `1px solid ${tokens.colorNeutralStroke1}`,
            padding: '12pt',
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        paddingBottom: '10px',
        marginBottom: '10px',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        gap: '10px',
        '@media print': {
            fontSize: '12pt',
            marginTop: '-4pt',
        },
    },
});
