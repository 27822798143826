import { IShimmerElement, IStyle, mergeStyleSets, Shimmer, ShimmerElementType } from '@fluentui/react';
import { Button, mergeClasses, tokens } from '@fluentui/react-components';
import { ChevronDownRegular, ChevronRightRegular } from '@fluentui/react-icons';
import { Fragment, isValidElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export enum ColumnType {
    Content,
    Action,
}

export enum ColumnAlignment {
    Left,
    Center,
    Right,
}

export enum ColumnResponsive {
    Any,
    Small,
    Large,
}

export type IColumnRender<T> = {
    (item: T): React.ReactElement | any | undefined;
};

export type IColumnConfig<T = any> = {
    alignment?: ColumnAlignment;
    responsive?: ColumnResponsive;
    onRender: IColumnRender<T>;
    onFooterRender?: IColumnRender<T[]>;
};

export type IActionColumnConfig<T = any> = Pick<IColumnConfig<T>, 'onRender' | 'responsive'>;

export type IColumn<T = any> = Required<Omit<IColumnConfig<T>, 'onFooterRender'>> &
    Pick<IColumnConfig<T>, 'onFooterRender'> & {
        label?: string;
        type: ColumnType;
    };

export function getColumn<T>(title: string, render: IColumnRender<T>): IColumn;
export function getColumn<T>(title: string, config: IColumnConfig<T>): IColumn;
export function getColumn<T>(title: string, renderOrConfig: IColumnRender<T> | IColumnConfig<T>): IColumn {
    if (typeof renderOrConfig === 'function') {
        return {
            label: title,
            type: ColumnType.Content,
            alignment: ColumnAlignment.Left,
            responsive: ColumnResponsive.Large,
            onRender: renderOrConfig,
        };
    } else {
        return {
            label: title,
            type: ColumnType.Content,
            alignment: renderOrConfig.alignment ?? ColumnAlignment.Left,
            responsive: renderOrConfig.responsive ?? ColumnResponsive.Large,
            onFooterRender: renderOrConfig.onFooterRender,
            onRender: renderOrConfig.onRender,
        };
    }
}

export function getActionColumn<T>(render: IColumnRender<T>): IColumn;
export function getActionColumn<T>(config: IActionColumnConfig<T>): IColumn;
export function getActionColumn<T>(renderOrConfig: IColumnRender<T> | IActionColumnConfig<T>): IColumn {
    if (typeof renderOrConfig === 'function') {
        return {
            type: ColumnType.Action,
            alignment: ColumnAlignment.Left,
            responsive: ColumnResponsive.Large,
            onRender: renderOrConfig,
        };
    } else {
        return {
            type: ColumnType.Action,
            alignment: ColumnAlignment.Left,
            responsive: renderOrConfig.responsive ?? ColumnResponsive.Large,
            onRender: renderOrConfig.onRender,
        };
    }
}

const rows = Array.from(Array(10));

const shimmer: IShimmerElement[] = [{ type: ShimmerElementType.line, height: 8 }];

const commonStyles = (columns: number, details: boolean, wide: boolean) => {
    const border: IStyle = {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: tokens.colorNeutralBackground5,
    };

    const common: IStyle = {
        transition: 'background-color 175ms ease',
        display: 'flex',
        alignContent: 'center',
        flexWrap: 'wrap',
        padding: 10,
    };

    return mergeStyleSets({
        container: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: details ? `32px repeat(${columns}, auto)` : `repeat(${columns}, auto)`,
        },
        containerCard: {
            boxShadow: tokens.shadow4,
            borderRadius: tokens.borderRadiusMedium,
            backgroundColor: tokens.colorNeutralBackground1,
            overflow: 'hidden',
        },
        chevron: {
            fontSize: '20px',
        },
        cell: {
            ...border,
            ...common,
        },
        hoverCell: {
            background: tokens.colorNeutralBackground5Hover,
        },
        headerCell: {
            ...common,
            fontWeight: 'bold',
        },
        firstCell: {
            paddingLeft: 20,
        },
        lastCell: {
            paddingRight: 20,
        },
        actionCell: {
            padding: 0,
            paddingLeft: 8,
            paddingRight: 8,
            justifyContent: 'right',
        },
        expandCell: {
            padding: 0,
            paddingLeft: 8,
            justifyContent: 'left',
        },
        shimmerCell: {
            ...border,
            padding: 16,
            paddingBottom: 15,
        },
        emptyCell: {
            display: 'block',
            textAlign: 'center',
            padding: 20,
            backgroundColor: tokens.colorNeutralBackground5,
            gridColumn: `span ${details ? columns + 1 : columns}`,
        },
        groupCell: {
            ...border,
            backgroundColor: tokens.colorNeutralBackground5Hover,
            gridColumn: `span ${details ? columns + 1 : columns}`,
            paddingLeft: 16,
            paddingRight: 20,
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            ':hover': {
                cursor: 'pointer',
                backgroundColor: tokens.colorNeutralBackground5,
            },
        },
        alignRightCell: {
            justifyContent: 'right',
        },
        alignCenterCell: {
            justifyContent: 'center',
        },
        groupExpand: {
            fontSize: 16,
        },
        detailsCell: {
            ...border,
            gridColumn: `span ${columns + 1}`,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: wide ? 'grid' : 'grid',
            gridTemplateColumns: '125px 1fr',
            flexDirection: 'column',
            columnGap: 8,
            rowGap: 4,
            backgroundColor: tokens.colorNeutralBackground2,
        },
        detailsAction: {
            gridColumn: `span 2`,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: tokens.colorNeutralStroke1,
            paddingTop: 8,
            marginTop: 8,
        },
        footerCell: {
            background: tokens.colorNeutralBackground5Hover,
            fontWeight: 'bold',
        },
    });
};

export type IListViewGroup<T = any> = {
    name: string;
    collapsed?: boolean;
    items: T[];
};

export type ListViewProps<T = any> = {
    enableShimmer?: boolean;
    items?: T[];
    groups?: IListViewGroup<T>[];
    columns: IColumn[];
    details?: IColumn[];
    plain?: boolean;
};

type HoverIndex = {
    group?: number;
    row: number;
};

export const ListView: React.FC<ListViewProps> = (props) => {
    const { t } = useTranslation('common');
    const [hovered, setHovered] = useState<HoverIndex>();
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [expandedDetails, setExpandedDetails] = useState<any[]>([]);

    const wide = useMediaQuery({ minWidth: 1024 }, undefined, () => setExpandedDetails([]));

    const available = (column: IColumn) => {
        if (column.responsive === ColumnResponsive.Any) {
            return true;
        } else {
            if (wide) {
                return column.responsive === ColumnResponsive.Large;
            } else {
                return column.responsive === ColumnResponsive.Small;
            }
        }
    };

    const availableColumns = props.columns.filter(available);
    const responsiveColumns = availableColumns.filter((column) => column.type !== ColumnType.Action);

    const columns = wide ? availableColumns : responsiveColumns.length ? responsiveColumns : props.columns.slice(0, 2);

    let detailColumns = props.details;

    if (!wide) {
        if (!detailColumns?.length) {
            detailColumns = props.columns.filter(
                (column) =>
                    !columns.includes(column) && (column.type !== ColumnType.Action || column.responsive !== ColumnResponsive.Large),
            );
        }

        detailColumns = [
            ...props.columns.filter(
                (column) =>
                    column.type !== ColumnType.Action &&
                    !available(column) &&
                    !detailColumns?.some((current) => current.label === column.label) &&
                    !columns?.some((current) => current.label === column.label),
            ),
            ...detailColumns,
        ];

        if (!detailColumns.length) {
            detailColumns = undefined;
        }
    }

    const showItems = !props.enableShimmer && !!props.items?.length;
    const showFooter = !props.enableShimmer && !!props.items?.length && !!columns?.some((column) => column.onFooterRender);
    const showGroups = !props.enableShimmer && !!props.groups?.length;
    const showEmpty = !props.enableShimmer && !props.items?.length && !props.groups?.length;
    const showShimmer = !!props.enableShimmer;

    const columnCount = columns.length;
    const columnDetails = !!detailColumns;

    const classes = useMemo(() => commonStyles(columnCount, columnDetails, wide), [columnCount, columnDetails, wide]);

    const toggleGroup = (group: IListViewGroup) => {
        if (expandedGroups.includes(group.name)) {
            setExpandedGroups(expandedGroups.filter((current) => current !== group.name));
        } else {
            setExpandedGroups([...expandedGroups, group.name]);
        }
    };

    const toggleDetails = (item: any) => {
        if (expandedDetails.includes(item)) {
            setExpandedDetails(expandedDetails.filter((current) => current !== item));
        } else {
            setExpandedDetails([...expandedDetails, item]);
        }
    };

    const commonCellClass = (columnIndex: number, column: IColumn) => {
        const baseClasses = [];

        if (column.type === ColumnType.Action) {
            baseClasses.push(classes.actionCell);
        } else {
            if (!columnDetails && columnIndex === 0) {
                baseClasses.push(classes.firstCell);
            }

            if (columnIndex === columns.length - 1) {
                baseClasses.push(classes.lastCell);
            }

            if (column.alignment === ColumnAlignment.Right) {
                baseClasses.push(classes.alignRightCell);
            } else if (column.alignment === ColumnAlignment.Center) {
                baseClasses.push(classes.alignCenterCell);
            }
        }

        return mergeClasses(...baseClasses);
    };

    const headerCellClass = (columnIndex: number, column: IColumn) => {
        const baseClasses = [classes.headerCell, commonCellClass(columnIndex, column)];

        return mergeClasses(...baseClasses);
    };

    const cellClass = (groupIndex: number | undefined, rowIndex: number, columnIndex: number, column: IColumn) => {
        const baseClasses = [classes.cell, commonCellClass(columnIndex, column)];

        if (groupIndex === hovered?.group && rowIndex === hovered?.row) {
            baseClasses.push(classes.hoverCell);
        }

        return mergeClasses(...baseClasses);
    };

    const footerClass = (columnIndex: number, column: IColumn) => {
        const baseClasses = [classes.cell, classes.footerCell, commonCellClass(columnIndex, column)];

        return mergeClasses(...baseClasses);
    };

    const expandClass = (base: string, groupIndex: number | undefined, rowIndex: number | undefined) => {
        const baseClasses = [base, classes.expandCell];

        if (rowIndex !== undefined && groupIndex === hovered?.group && rowIndex === hovered?.row) {
            baseClasses.push(classes.hoverCell);
        }

        return mergeClasses(...baseClasses);
    };

    const containerClass = () => {
        const baseClasses = [classes.container];

        if (!props.plain) {
            baseClasses.push(classes.containerCard);
        }

        return mergeClasses(...baseClasses);
    };

    const renderValue = (item: any, column: IColumnConfig<any>) => {
        if (item) {
            const render = column.onRender(item);

            if (render !== null && render !== undefined) {
                if (isValidElement(render)) {
                    return render;
                } else {
                    return render.toString();
                }
            }
        }

        return null;
    };

    const renderRow = (row: any, rowIndex: number, groupIndex: number | undefined) => (
        <Fragment key={`${groupIndex}-${rowIndex}-row`}>
            {detailColumns && (
                <div
                    className={expandClass(classes.cell, groupIndex, rowIndex)}
                    onMouseEnter={() => setHovered({ group: groupIndex, row: rowIndex })}
                    onMouseLeave={() => setHovered(undefined)}
                >
                    <Button
                        appearance="subtle"
                        icon={expandedDetails.includes(row) ? <ChevronDownRegular /> : <ChevronRightRegular />}
                        onClick={() => toggleDetails(row)}
                    />
                </div>
            )}
            {columns.map((column, columnIndex) => (
                <div
                    className={cellClass(groupIndex, rowIndex, columnIndex, column)}
                    key={`${groupIndex}-${rowIndex}-${columnIndex}-column`}
                    onMouseEnter={() => setHovered({ group: groupIndex, row: rowIndex })}
                    onMouseLeave={() => setHovered(undefined)}
                >
                    {renderValue(row, column)}
                </div>
            ))}
            {expandedDetails.includes(row) && (
                <div className={classes.detailsCell}>
                    {detailColumns
                        ?.filter((detail) => detail.type === ColumnType.Content)
                        .map((detail) => ({ ...detail, renderValue: renderValue(row, detail) }))
                        .filter((detail) => detail.renderValue)
                        .map((detail, detailIndex) => (
                            <Fragment key={`${groupIndex}-${rowIndex}-${detailIndex}-detail`}>
                                <b>{detail.label}</b>
                                <div>{detail.renderValue}</div>
                            </Fragment>
                        ))}
                    {detailColumns
                        ?.filter((detail) => detail.type === ColumnType.Action)
                        .map((detail, detailIndex) => (
                            <div className={classes.detailsAction} key={`${groupIndex}-${rowIndex}-${detailIndex}-action`}>
                                {renderValue(row, detail)}
                            </div>
                        ))}
                </div>
            )}
        </Fragment>
    );

    const renderHeader = () => (
        <Fragment>
            {detailColumns && <div className={expandClass(classes.headerCell, undefined, undefined)} />}
            {columns.map((column, columnIndex) => (
                <div key={columnIndex} className={headerCellClass(columnIndex, column)}>
                    {column.label}
                </div>
            ))}
        </Fragment>
    );

    const renderEmpty = () => showEmpty && <div className={classes.emptyCell}>{t('noData')}</div>;

    const renderShimmer = () =>
        showShimmer &&
        rows.map((_, rowIndex) => (
            <Fragment key={rowIndex}>
                {detailColumns && <div className={expandClass(classes.cell, undefined, rowIndex)} />}
                {columns.map((_, columnIndex) => (
                    <div className={classes.shimmerCell} key={`${rowIndex}-${columnIndex}`}>
                        <Shimmer shimmerElements={shimmer} />
                    </div>
                ))}
            </Fragment>
        ));

    return (
        <div className={containerClass()}>
            {renderHeader()}
            {renderEmpty()}
            {showItems && props.items?.map((row, rowIndex) => renderRow(row, rowIndex, undefined))}
            {showFooter &&
                columns.map((column, columnIdex) => (
                    <div className={footerClass(columnIdex, column)} key={columnIdex}>
                        {column.onFooterRender?.(props.items ?? [])}
                    </div>
                ))}
            {showGroups &&
                props.groups?.map((group, groupIndex) => (
                    <Fragment key={groupIndex}>
                        <div className={classes.groupCell} key={groupIndex} onClick={() => toggleGroup(group)}>
                            {expandedGroups.includes(group.name) ? (
                                <ChevronDownRegular className={classes.chevron} />
                            ) : (
                                <ChevronRightRegular className={classes.chevron} />
                            )}
                            {group.name}
                        </div>
                        {(function () {
                            return (
                                expandedGroups.includes(group.name) &&
                                group.items.map((row, rowIndex) => renderRow(row, rowIndex, groupIndex))
                            );
                        })()}
                    </Fragment>
                ))}
            {renderShimmer()}
        </div>
    );
};
