import { Dropdown, Field, makeStyles, mergeClasses, Option, Select, SelectProps } from '@fluentui/react-components';
import { Controller } from 'react-hook-form';

import { mobile, validate } from './helpers';
import { ControllerFieldProps } from './models';

export type SelectOption = {
    key: any;
    text: string;
};

export type SelectFieldProps = Omit<SelectProps, 'multiple' | 'children' | 'select'> & {
    label?: string;
    select?: { className?: string };
    options: SelectOption[];
};

export const SelectField: React.FC<SelectFieldProps & ControllerFieldProps> = ({ control, rules, label, className, options, ...props }) => {
    const classes = useStyles();

    return (
        <Controller
            defaultValue={props.defaultValue}
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} {...validate(fieldState)} className={className}>
                    {mobile ? (
                        <Select
                            {...props}
                            {...field}
                            className={mergeClasses(classes.wrapper, props.select?.className)}
                            select={{ className: mergeClasses(classes.select, props.select?.className) }}
                        >
                            {options.map((option, index) => (
                                <option key={option.key ?? index} value={option.key}>
                                    {option.text}
                                </option>
                            ))}
                        </Select>
                    ) : (
                        <Dropdown
                            disabled={props.disabled}
                            className={mergeClasses(classes.dropdown, props.select?.className)}
                            value={options.find((option) => option.key === field.value)?.text ?? field.value ?? ''}
                            onOptionSelect={(_, data) => field.onChange(data.optionValue)}
                            onBlur={field.onBlur}
                            selectedOptions={[field.value]}
                        >
                            {options.map((option, index) => (
                                <Option key={option.key ?? index} value={option.key} text={option.text}>
                                    {option.text}
                                </Option>
                            ))}
                        </Dropdown>
                    )}
                </Field>
            )}
        />
    );
};

const useStyles = makeStyles({
    wrapper: {
        overflow: 'hidden',
    },
    dropdown: {
        minWidth: 'unset',
    },
    select: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});
