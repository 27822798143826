import {
    AccountInfo,
    AuthenticationResult,
    AuthorizationCodeRequest,
    BrowserConfiguration,
    ClearCacheRequest,
    EndSessionPopupRequest,
    EndSessionRequest,
    EventCallbackFunction,
    EventMessage,
    EventType,
    INavigationClient,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    ITokenCache,
    Logger,
    PerformanceCallbackFunction,
    PopupRequest,
    PublicClientApplication,
    RedirectRequest,
    ServerError,
    SilentRequest,
    SsoSilentRequest,
    WrapperSKU,
} from '@azure/msal-browser';
import { AccountFilter } from '@azure/msal-common';

import { msal as config } from '../config';
import { generateEmergencyRefreshToken, redeemEmergencyRefreshToken } from '../refresh-tokens/auth';
import { logError } from './application-insights';

type Event<T> = Omit<EventMessage, 'payload'> & { payload: T };

export function ofType(eventType: 'msal:loginSuccess', event: EventMessage): event is Event<AccountInfo>;
export function ofType(eventType: 'msal:acquireTokenSuccess', event: EventMessage): event is Event<AuthenticationResult>;
export function ofType(type: EventType, event: EventMessage): boolean {
    return event.eventType === type;
}

class MockClientApplication implements IPublicClientApplication {
    private accounts: AccountInfo[];
    private logger: Logger;
    private authenticationResult: AuthenticationResult;

    constructor(mockRoles: string[]) {
        this.logger = new Logger({});

        this.accounts = [
            {
                homeAccountId: '11111111-2222-3333-4444-555555555555-b2c_1a_signup_signin.00000000-0000-0000-0000-000000000000',
                environment: 'c2dprod.b2clogin.com',
                tenantId: '00000000-0000-0000-0000-000000000000',
                username: '',
                localAccountId: '11111111-2222-3333-4444-555555555555',
                name: 'Local User',
                idTokenClaims: {
                    ver: '1.0',
                    iss: 'https://c2ddev.b2clogin.local/00000000-0000-0000-0000-000000000000/v2.0/',
                    sub: '11111111-2222-3333-4444-555555555555',
                    aud: '00000000-0000-0000-0000-000000000000',
                    exp: 1800000000,
                    acr: 'b2c_1a_signup_signin',
                    nonce: '00000000-0000-0000-0000-000000000000',
                    iat: 1800000000,
                    auth_time: 1715000000,
                    username: '',
                    name: 'Local User',
                    given_name: 'Local',
                    family_name: 'User',
                    groups: mockRoles,
                    idp: 'Local',
                    tid: '00000000-0000-0000-0000-000000000000',
                    at_hash: 'WIHaT3gl_VT_WAugaGspWw',
                    nbf: 1800000000,
                },
                authorityType: 'MSSTS',
            },
        ];

        this.authenticationResult = {
            authority: '00000000-0000-0000-0000-000000000000',
            uniqueId: '00000000-0000-0000-0000-000000000000',
            tenantId: '00000000-0000-0000-0000-000000000000',
            scopes: [],
            idToken: 'id-token',
            idTokenClaims: {},
            accessToken: 'access-token',
            fromCache: true,
            expiresOn: null,
            tokenType: 'access-token',
            correlationId: '00000000-0000-0000-0000-000000000000',
            account: this.accounts[0],
        };
    }

    initialize(): Promise<void> {
        return Promise.resolve(void 0);
    }

    acquireTokenPopup(request: PopupRequest): Promise<AuthenticationResult> {
        return Promise.resolve(this.authenticationResult);
    }

    acquireTokenRedirect(request: RedirectRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    acquireTokenSilent(silentRequest: SilentRequest): Promise<AuthenticationResult> {
        return Promise.resolve(this.authenticationResult);
    }

    acquireTokenByCode(request: AuthorizationCodeRequest): Promise<AuthenticationResult> {
        return Promise.resolve(this.authenticationResult);
    }

    addEventCallback(callback: EventCallbackFunction): string | null {
        callback({
            eventType: EventType.ACQUIRE_TOKEN_SUCCESS,
            payload: { account: this.accounts[0] },
        } as any);

        return null;
    }

    removeEventCallback(callbackId: string): void {}

    addPerformanceCallback(callback: PerformanceCallbackFunction): string {
        return '';
    }

    removePerformanceCallback(callbackId: string): boolean {
        return true;
    }

    enableAccountStorageEvents(): void {}

    disableAccountStorageEvents(): void {}

    getAccount(accountFilter: AccountFilter): AccountInfo | null {
        return null;
    }

    getAccountByHomeId(homeAccountId: string): AccountInfo | null {
        return null;
    }

    getAccountByLocalId(localId: string): AccountInfo | null {
        return null;
    }

    getAccountByUsername(userName: string): AccountInfo | null {
        return null;
    }

    getAllAccounts(): AccountInfo[] {
        return this.accounts;
    }

    handleRedirectPromise(hash?: string): Promise<AuthenticationResult | null> {
        return Promise.resolve(this.authenticationResult);
    }

    loginPopup(request?: PopupRequest): Promise<AuthenticationResult> {
        return Promise.resolve(this.authenticationResult);
    }

    loginRedirect(request?: RedirectRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    logout(logoutRequest?: EndSessionRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    logoutRedirect(logoutRequest?: EndSessionRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    logoutPopup(logoutRequest?: EndSessionPopupRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    ssoSilent(request: SsoSilentRequest): Promise<AuthenticationResult> {
        return Promise.resolve(this.authenticationResult);
    }

    getTokenCache(): ITokenCache {
        throw new Error('Method not implemented.');
    }

    getLogger(): Logger {
        return this.logger;
    }

    setLogger(logger: Logger): void {}

    setActiveAccount(account: AccountInfo | null): void {}

    getActiveAccount(): AccountInfo | null {
        return this.accounts[0];
    }

    initializeWrapperLibrary(sku: WrapperSKU, version: string): void {}

    setNavigationClient(navigationClient: INavigationClient): void {}

    getConfiguration(): BrowserConfiguration {
        throw new Error('Method not implemented.');
    }

    hydrateCache(result: AuthenticationResult, request: SilentRequest | RedirectRequest | PopupRequest | SsoSilentRequest): Promise<void> {
        return Promise.resolve(void 0);
    }

    clearCache(logoutRequest?: ClearCacheRequest): Promise<void> {
        return Promise.resolve(void 0);
    }
}

export const scopes = config.scopes;

export const msal = config.mock
    ? new MockClientApplication(config.mockRoles)
    : new PublicClientApplication({
          auth: {
              clientId: config.clientId,
              authority: config.authority,
              knownAuthorities: [config.authority],
              redirectUri: window.location.origin,
              navigateToLoginRequestUrl: true,
          },
          cache: {
              cacheLocation: 'localStorage',
          },
          // system: {
          //   loggerOptions: {
          //     loggerCallback: (level, message, containsPii) => console.log(message),
          //     logLevel: LogLevel.Verbose,
          //   },
          // },
      });

const accounts = msal.getAllAccounts();

// Default to using the first account if no account is active on page load
if (!msal.getActiveAccount() && accounts.length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msal.setActiveAccount(accounts[0]);
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6112
msal.addEventCallback((event) => {
    if (ofType(EventType.ACQUIRE_TOKEN_SUCCESS, event)) {
        msal.setActiveAccount(event.payload.account);
        generateEmergencyRefreshToken(event.payload.accessToken);
    } else if (ofType(EventType.LOGIN_SUCCESS, event)) {
        msal.setActiveAccount(event.payload);
    }
});

export function getClaim<T>(account: AccountInfo, claim: string) {
    return account?.idTokenClaims?.[claim] as T | undefined;
}

export function hasClaim(account: AccountInfo, claim: string) {
    return getClaim(account, claim) !== undefined;
}

export async function getToken(msal: IPublicClientApplication) {
    try {
        const [account] = msal.getAllAccounts();

        return await msal.acquireTokenSilent({ scopes, account });
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await msal.acquireTokenRedirect({ scopes });
        } else if (error instanceof ServerError) {
            logError(error);

            try {
                const refreshToken = await redeemEmergencyRefreshToken();

                if (refreshToken) {
                    return { accessToken: refreshToken.accessToken };
                }
            } catch (redeemRefreshTokenError) {
                if (redeemRefreshTokenError instanceof Error) {
                    logError(redeemRefreshTokenError);
                }
            }
        }

        throw error;
    }
}
