import { createTheme } from '@fluentui/react';
import { createDarkTheme, createLightTheme } from '@fluentui/react-components';

export const theme = createLightTheme({
    10: '#010305',
    20: '#0f1920',
    30: '#112938',
    40: '#11364b',
    50: '#0e435f',
    60: '#045173',
    70: '#1e5e81',
    80: '#376b8b',
    90: '#4c7896',
    100: '#5f86a1',
    110: '#7293ac',
    120: '#85a1b7',
    130: '#98afc2',
    140: '#aabdcd',
    150: '#bdccd8',
    160: '#d0dbe3',
});

export const darkTheme = createDarkTheme({
    10: '#010305',
    20: '#0f1920',
    30: '#112938',
    40: '#11364b',
    50: '#0e435f',
    60: '#045173',
    70: '#1e5e81',
    80: '#376b8b',
    90: '#4c7896',
    100: '#5f86a1',
    110: '#7293ac',
    120: '#85a1b7',
    130: '#98afc2',
    140: '#aabdcd',
    150: '#bdccd8',
    160: '#d0dbe3',
});

export const legacyTheme = createTheme({
    palette: {
        themePrimary: '#00567a',
        themeLighterAlt: '#f0f7fa',
        themeLighter: '#c4dfea',
        themeLight: '#97c4d7',
        themeTertiary: '#4690af',
        themeSecondary: '#11668a',
        themeDarkAlt: '#004d6e',
        themeDark: '#00415d',
        themeDarker: '#003045',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
});
