import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@fluentui/react';
import { FluentProvider } from '@fluentui/react-components';
import { PortalCompatProvider } from '@fluentui/react-portal-compat';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { msal } from './common';
import { TranslationProvider } from './common';
import { legacyTheme, theme } from './theme';

// compatibility with fui 8
initializeIcons();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <FluentProvider theme={theme}>
            <PortalCompatProvider>
                <ThemeProvider applyTo="body" theme={legacyTheme}>
                    <MsalProvider instance={msal}>
                        <TranslationProvider>
                            <App />
                        </TranslationProvider>
                    </MsalProvider>
                </ThemeProvider>
            </PortalCompatProvider>
        </FluentProvider>
    </React.StrictMode>,
);
