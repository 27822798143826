import { makeStyles, Title3, tokens } from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';

import { Link } from '../common';
import { versions } from '../versions';
import { Logo } from './Logo';

export const Brand: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const linkClick = (url: string) => (event: React.MouseEvent) => {
        event.preventDefault();
        navigate(url);
    };

    return (
        <section className={classes.root}>
            <Link className={classes.name} href="/" onClick={linkClick('/')}>
                <Logo height="20" fill="white" />
                <Title3 className={classes.title}>Backoffice</Title3>
            </Link>
            <Link className={classes.version} href="/system/release-notes" onClick={linkClick('/system/release-notes')}>
                v{versions[0].version}
            </Link>
        </section>
    );
};

const useStyles = makeStyles({
    root: {
        flexShrink: 0,
        height: '60px',
        width: '225px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: tokens.colorBrandBackgroundSelected,
    },
    name: {
        color: tokens.colorNeutralForegroundInverted,
        fontWeight: 'bold',
        display: 'inline-flex',
        gap: tokens.spacingVerticalXS,
        lineHeight: 'unset',
        ':hover, :active, focus, :active:hover': {
            color: tokens.colorNeutralForegroundInverted,
            textDecoration: 'none',
        },
    },
    version: {
        color: tokens.colorNeutralForegroundInverted,
        marginLeft: '8px',
        marginTop: '-8px',
        opacity: 0.4,
        ':hover, :active, focus, :active:hover': {
            color: tokens.colorNeutralForegroundInverted,
            textDecoration: 'none',
            opacity: 0.6,
        },
    },
    title: {
        color: tokens.colorNeutralForegroundInverted,
        opacity: 0.7,
        lineHeight: 'unset',
        '@media print': {
            color: tokens.colorNeutralForeground1,
        },
    },
});
