import { makeStyles, MessageBar, MessageBarBody, tokens } from '@fluentui/react-components';
import { useEffect, useRef, useState } from 'react';

import { Message, usePageMessages } from '../hooks';

type Toaster = {
    last?: Message;
    visible: Message[];
};

export const MessageToaster: React.FC = () => {
    const classes = useStyles();
    const messageTimeouts = useRef(new Map<string, any>());
    const { messages } = usePageMessages();
    const [toaster, setToaster] = useState<Toaster>({ visible: [] });

    useEffect(() => {
        setToaster(({ last, visible }) => {
            const added = messages.filter((message) => !last || message.time > last.time).reverse();

            added.forEach((message) =>
                messageTimeouts.current.set(
                    message.id,
                    setTimeout(() => remove(message), 10000),
                ),
            );

            return {
                last: added[0] ?? last,
                visible: [...added, ...visible],
            };
        });
    }, [messages]);

    const remove = (message: Message) => {
        const timeout = messageTimeouts.current.get(message.id);
        messageTimeouts.current.delete(message.id);

        clearTimeout(timeout);
        setToaster(({ last, visible }) => ({
            last,
            visible: visible.filter((current) => current !== message),
        }));
    };

    return (
        <div className={classes.root}>
            {toaster.visible.map((message) => (
                <MessageBar key={message.id} intent={message.type} className={classes.message} icon={{ className: classes.icon }}>
                    <MessageBarBody className={classes.body}>
                        {[message.message, message.details].filter((segment) => segment).join(' - ')}
                    </MessageBarBody>
                </MessageBar>
            ))}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '60px',
        right: '0',
        zIndex: 100,
        width: '480px',
        maxWidth: `calc(100% - 2 * ${tokens.spacingHorizontalM})`,
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalM}`,
        gap: tokens.spacingVerticalM,
    },
    message: {
        alignItems: 'start',
        boxShadow: tokens.shadow8,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
        whiteSpace: 'normal',
        gap: tokens.spacingVerticalS,
    },
    icon: {
        marginTop: tokens.spacingVerticalS,
    },
});
